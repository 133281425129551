B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'PrepareTirageCtrl', ['$scope', '$timeout', '$http',  '$window',($scope, $timeout, $http, $window) ->
  
  $scope.init = () ->
    console.log("ok")
  
  $scope.open_create_galery = (current_social_network, total_pictures_done) ->
    
    $('.modal').hide()
    $scope.current_social_network = current_social_network
    $scope.count_picture = total_pictures_done
    
    #tirage_modal = new bootstrap.Modal(document.getElementById('tirages_modal'), {})
    #tirage_modal.hide();
    
    $scope.modal_create_gallery = new bootstrap.Modal(document.getElementById('create_gallery'), {})
    $scope.modal_create_gallery.show();
    

  $scope.valid_gallery = () ->
    $.ajax
      url: "/social_networks/"+$scope.current_social_network.id
      type: 'PUT'
      data: {social_network: {name: $scope.current_social_network.name}}
      success: (result) ->
        window.location.href = "/social_networks/"+$scope.current_social_network.id+"/albums?category=tirages"
]
