// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage";
import "channels";

window.global = window;
global.$ = require("jquery");
window.$ = global.$;

require("jquery");
require("jquery-ui");
//require('jquery-ui-dist/jquery-ui');
//require("jquery-ujs")
//require("jquery-ui/ui/widgets/draggable.js")

//require("guillotine/js/jquery.guillotine.js")

import "../js/services/uploader/vendor/jquery.ui.widget.js";

//import '../js/welcome/aos.js'
import AOS from "aos";

Rails.start();
//Turbolinks.start()
ActiveStorage.start();


window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");

import "../js/angular/angular.js";
import "../js/angular/angular-resource.js";
import "../js/angular/angular-sanitize.js";

import "@fortawesome/fontawesome-free/js/all";

import "../js/services/uploader/vendor/jquery.ui.widget.js";
import "../js/services/uploader/jquery.fileupload.js";

//import 'bootstrap-colorpicker'

// Define the `phonecatApp` module
window.B2cApp = angular.module("B2cApp", []);

require("js/controllers/WelcomeCtrl.js");
require("js/controllers/YearbookCtrl.js");
require("js/controllers/PaymentCtrl.js");
require("js/controllers/FooterCtrl.js");
require("js/controllers/RegisterCtrl.js");
require("js/controllers/UploaderCtrl.js");
require("js/controllers/PrepareTirageCtrl.js");


import "../js/services/jquery.flipper-responsive.js";

//require("js/controllers/EditorCtrl.js");
//require("js/controllers/UploaderCtrl.js");

$(document).ready(function () {
  
  
  window.copyIt = function () {
    let copyInput = document.querySelector('#copyvalue');

    copyInput.select();

    document.execCommand("copy");

    $('.copybtn').html("COPIÉ !");
  }
  
  setTimeout(() => {
    $( ".card_promo" ).effect( "shake" );
  }, 1000);

  

  
  
  $("#myFlipper").flipper("init");

  var totalItems = $(".item").length;
  console.log(totalItems);
  var currentIndex = $("div.active").index() + 1;

  //$("#myCarousel").carousel();
  //$("#myCarousel-souple").carousel();
  //$("#myCarousel-rigide").carousel();

  $("#myCarousel").bind("slid", function () {
    currentIndex = $("#myCarousel div.active").index() + 1;
    $(".num-right-modal").html(totalItems);
  });
  $("#myCarousel").on("slide.bs.carousel", function () {
    var currentIndex = $("#myCarousel div.active").index() + 1;

    $(".num-left-modal").html(("0" + currentIndex).slice(-2));
  });
  // myCarousel-souple
  $("#myCarousel-souple").bind("slid", function () {
    currentIndex = $("#myCarousel-souple div.active").index() + 1;

    $(".num-right-modal-souple").html(totalItems);
  });
  $("#myCarousel-souple").on("slide.bs.carousel", function () {
    var currentIndex = $("#myCarousel-souple div.active").index() + 1;

    $(".num-left-modal-souple").html(("0" + currentIndex).slice(-2));
  });

  // myCarousel-rigide

  $("#myCarousel-rigide").bind("slid", function () {
    currentIndex = $("#myCarousel-rigide div.active").index() + 1;
    $(".num-right-modal-rigide").html(totalItems);
  });
  $("#myCarousel-rigide").on("slide.bs.carousel", function () {
    var currentIndex = $("#myCarousel-rigide div.active").index() + 1;

    $(".num-left-modal-rigide").html(("0" + currentIndex).slice(-2));
  });

  jQuery(".galerie").mouseover(function () {
    $(this).find("img").show();
    console.log("lll");
  });
  jQuery(".galerie").mouseout(function () {
    $(this).find("img").hide();
    console.log("qsd");
  });
});

$(document).ready(function () {
  window.afficherModal = function () {
    var modal = document.getElementById("myModal");
    var btn = document.getElementsByClassName("myBtn");
    $("html, body").css("overflow", "hidden");
    $(".retour").hide();
    $(".compte-rebours").hide();

    modal.style.display = "block";
  };

  window.closeModal = function () {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
    console.log("closed");
    $("html, body").css("overflow", "auto");
    $(".retour").show();
    $(".compte-rebours").show();
  };

  window.show = function (el) {
    console.log("show");

    console.log(el.currentTarget);
  };
  window.hide = function (el) {
    console.log("hide");
    console.log(el.target);
  };
  window.backToTop = function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  window.openModalSaveMail = function () {
    new bootstrap.Modal(document.getElementById("save_mail_modal"), {}).show();
  };

  window.backToTop = function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  function MasquerBandeau(bandeauLivraison) {
    if (document.getElementById("bandeauLivraison").style.display == "block")
      document.getElementById("bandeauLivraison").style.display = "none";
  }
  function masquerBandeau() {
    var modal = document.getElementById("bandeauLivraison");
    modal.style.display = "none";
  }

  $(document).ready(function () {
    //$(".img_arrow_close img").onclick(function () {
    //  $("#panier_modal").hide();
    //});
  });

  $(document).ready(function () {
    $(".quantity-field").attr({
      min: 20,
    });
  });

  $(document).ready(function () {
    console.log("close");
    $(".btn-close").click(function () {
      $(".modal-backdrop.show").css("z-index", "inherit");
    });
  });

  //window.onload = function() {
  //  //Get the button
  //  let mybutton = document.getElementById("btn-back-to-top");
  //
  //  // When the user scrolls down 20px from the top of the document, show the button
  //  window.onscroll = function () {
  //    scrollFunction();
  //  };
  //
  //  function scrollFunction() {
  //    if (
  //      document.body.scrollTop > 20 ||
  //      document.documentElement.scrollTop > 20
  //    ) {
  //      mybutton.style.display = "block";
  //    }
  //  }
  //  // When the user clicks on the button, scroll to the top of the document
  //  mybutton.addEventListener("click", backToTop);
  //
  //  function backToTop() {
  //    document.body.scrollTop = 0;
  //    document.documentElement.scrollTop = 0;
  //  }
  //};
});
// // function onHover() {
// //   $(".instagram").attr("src", "welcome/v2/instagram.svg");
// // }

// // function offHover() {
// //   $(".instagram").attr("src", "welcome/v2/instagram_bouton_on.svg");
// // }
